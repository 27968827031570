import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import { Button, Dialog, Form, Field, Swipe, SwipeItem, Lazyload, Tab, Tabs, Overlay, List, ImagePreview, Toast } from 'vant'
Vue.use(Vant)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(Field)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Overlay)
Vue.use(List)
Vue.use(ImagePreview)
Vue.use(Toast)
Vue.prototype.$ImagePreview = ImagePreview
Vue.prototype.$Toast = Toast
