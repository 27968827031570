<template>
  <div id="nav">
    <!-- :include="['NewsList']" -->
    <keep-alive :include="keepAliveKey">
      <router-view />
    </keep-alive>
    <!-- <router-view v-if="!$route.meta.keepAlive" /> -->
  </div>

</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapState(['keepAliveKey'])
  }
}
</script>

<style>
/*
关闭黑白滤镜
body{
  filter: grayscale(1);
} */
</style>
