import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import 'video.js/dist/video-js.css'
import 'babel-polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import VueSwiper from 'vue-awesome-swiper'
import VueTouch from 'vue-touch'
import store from './store'
import 'swiper/css/swiper.css'
import '@/utils/rem.js'
import '@/style/index.css'
import './utils/vant';
import md5 from 'js-md5';

Vue.use(VueSwiper)
Vue.use(VueTouch, { name: 'v-touch' })

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
Vue.prototype.$md5 = md5;
