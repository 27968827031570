import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    keepAliveKey: ['NewsList']
  },
  mutations: {
    ADD_keepAliveKey(state, value) {
      state.keepAliveKey.push(value)
    },
    DEL_ADD_keepAliveKey(state, value) {
      state.keepAliveKey = state.keepAliveKey.filter(item => item !== value)
    }
  }
})

export default store
